<template>
  <div class="card">
    <div class="card-header border-0 pt-6">
      <div class="card-title">
        <div class="d-flex align-items-center position-relative my-1">
          <!-- <span class="svg-icon svg-icon-1 position-absolute ms-6">
            <inline-svg src="media/icons/duotune/general/gen021.svg" />
          </span>
          <input
            type="text"
            class="form-control form-control-solid w-250px ps-15"
            placeholder="Recherche"
          /> -->&nbsp;
        </div>
      </div>
      <div class="card-toolbar">
        <div
          class="d-flex justify-content-end"
          data-kt-customer-table-toolbar="base"
        >
          <!-- <button type="button" class="btn btn-light-primary me-3">
            <span class="svg-icon svg-icon-2">
              <inline-svg src="media/icons/duotune/arrows/arr078.svg" />
            </span>
            Export
          </button> -->
          <button
            type="button"
            class="btn btn-primary btn-sm"
            data-bs-toggle="modal"
            data-bs-target="#kt_modal_user"
            style="float: right"
            @click="maj('', 'ajouter')"
          >
            <span class="svg-icon svg-icon-2">
              <inline-svg src="media/icons/duotone/Navigation/Plus.svg" />
            </span>
            Ajouter
          </button>
        </div>
      </div>
    </div>
    <div class="card-body pt-0">
      <div class="dataTables_wrapper dt-bootstrap4 no-footer">
        <div class="table-responsive">
          <table class="table table-rounded table-striped border gy-7 gs-7">
            <thead>
              <tr
                class="fw-bold fs-5 text-gray-800 border-bottom border-gray-200"
              >
                <th>Nom</th>
                <th>Prénom</th>
                <th>Email</th>
                <th>Type</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in data.data" :key="item.id">
                <td>{{ item.name }}</td>
                <td>{{ item.firstname }}</td>
                <td>{{ item.email }}</td>
                <td>{{ item.nom_type_user }}</td>
                <td>
                  <!-- <input
            type="checkbox"
            @click="IsActiveUser(item)"
            true-value="1"
            false-value="0"
            v-model="item.isActive"
          /> -->
                  <Toggle
                    class="toggle-blue"
                    v-model="item.isActive"
                    @click="IsActiveUser(item)"
                  />
                </td>
                <!-- <td>
          <span class="action_icon"
            ><i
              class="fa fa-edit fa-2x"
              @click="maj(item, 'editer')"
              style="cursor: pointer"
              data-bs-toggle="modal"
              data-bs-target="#kt_modal_1"
            ></i
            >Edit</span
          >
        </td> -->
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
  <div class="modal fade" tabindex="-1" id="kt_modal_user">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{ formMode }} Utilisateur</h5>
          <!--begin::Close-->
          <div
            class="btn btn-icon btn-sm btn-active-light-primary ms-2"
            data-bs-dismiss="modal"
            aria-label="Close"
            @click="resetData()"
          >
            <span class="svg-icon svg-icon-2x"></span>
          </div>
          <!--end::Close-->
        </div>
        <!--Start Body modal add user-->
        <div class="modal-body">
          <form class="row g-3">
            <div class="row">
              <div class="col-md-6 padding_left_none">
                <label for="name" class="form-label required">Nom </label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  class="form-control m-2"
                  v-model="add_data.name"
                />
              </div>
              <div class="col-md-6">
                <label for="firstname" class="form-label required"
                  >Prénom
                </label>
                <input
                  type="text"
                  id="firstname"
                  name="firstname"
                  class="form-control m-2"
                  v-model="add_data.firstname"
                />
              </div>
            </div>
            <div class="row">
              <div class="col-md-6 padding_left_none">
                <label for="email" class="form-label required">Email </label>
                <input
                  type="text"
                  id="email"
                  name="email"
                  class="form-control m-2"
                  v-model="add_data.email"
                />
              </div>
            </div>
            <div class="row" v-if="formMode == 'ajouter'">
              <div class="col-md-6 padding_left_none">
                <label for="inputEmail4" class="form-label required"
                  >créer un mot de passe
                </label>
                <input
                  type="password"
                  id="password"
                  name="password"
                  class="form-control m-2"
                  v-model="add_data.password"
                />
              </div>
              <div class="col-md-6">
                <label for="password_confirmation" class="form-label required"
                  >confirmation du mot de passe
                </label>
                <input
                  type="password"
                  id="password_confirmation"
                  name="password_confirmation"
                  class="form-control m-2"
                  v-model="add_data.password_confirmation"
                />
              </div>
            </div>

            <br />
          </form>
        </div>
        <!--End Body modal add user-->
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-light"
            data-bs-dismiss="modal"
            @click="resetData()"
          >
            Fermer
          </button>
          <button type="button" class="btn btn-primary" @click="submit()">
            Enregistrer
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import { defineComponent, onMounted, ref } from "vue";
// import { config } from "@/core/helpers/config";
// import CodeHighlighter from "@/components/highlighters/CodeHighlighter.vue";
// import { themeName } from "@/core/helpers/documentation";
// import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import { ApiService } from "@/core/services/ApiService";
import Swal from "sweetalert2/dist/sweetalert2.min.js";

import Toggle from "@vueform/toggle";

export default {
  components: {
    Toggle,
  },
  data() {
    return {
      data: {},
      add_data: {
        typeusers_id: 2,
        regions_id: 0,
        sexe: "Homme",
        IsActive: 1,
      },
      showModal: false,
      regions: {},
      formMode: "ajouter",
      currentYear: new Date().getFullYear(),
      tabYears: [],
    };
  },
  methods: {
    getlisteYear() {
      const start = 1920;
      const end = this.currentYear;
      let list = Array(end - start + 1)
        .fill()
        .map((_, idx) => start + idx);
      this.tabYears = list.reverse();
    },
    IsActiveUser(item) {
      ApiService.setHeader();
      ApiService.post("/user/isActive", item)
        .then((resp) => {
          console.log(resp);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getListeUser() {
      ApiService.setHeader();
      ApiService.get("user/all")
        .then((result) => {
          this.data = result;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    submit() {
      if (this.formMode == "ajouter") {
        ApiService.setHeader();
        ApiService.post("user/Insert", this.add_data)
          .then((response) => {
              this.alertSucess('Enregistrer avec succes!','success');
          })
          .catch(({ response }) => {
              if(response.data.email){
                  this.alertSucess(response.data.email,'error');
              }
              if(response.data.password){
                  this.alertSucess(response.data.email,'error');
              }            
          });
      } else {
        ApiService.setHeader();
        ApiService.post("user/Update", this.add_data)
          .then((response) => {
            this.alertSucess('Enregistrer avec succes!','success');
          })
          .catch(({ response }) => {
              if(response.data.email){
                  this.alertSucess(response.data.email,'error');
              }
              if(response.data.password){
                  this.alertSucess(response.data.email,'error');
              }     
          });
      }
    },
    resetData() {
      this.add_data = {};
    },
    maj(item, val) {
      this.formMode = val;
      if (val == "editer") {
        const data_tmp = JSON.parse(JSON.stringify(item));
        this.add_data = data_tmp;
      }
    },
     alertSucess(msg,status) {
      Swal.fire({
        text: msg,
        icon: status,
        buttonsStyling: false,
        confirmButtonText: "Ok!",
        customClass: {
          confirmButton: "btn fw-bold btn-light-primary",
        },
      }).then(function () {
        if(status == 'success'){
            location.reload();
        }        
      });
    },
  },
  mounted() {
    this.getListeUser();
    this.getlisteYear();
  },
};
</script>

<style src="@vueform/toggle/themes/default.css"></style>
<style>
.toggle-blue {
  --toggle-bg-on: #009ef7 !important;
  --toggle-border-on: #009ef7 !important;
}
</style>
